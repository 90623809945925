<template>
    <div>
      <div class="mb-3 w-100 shadow-sm bg-white rounded-lg">

        <div class="col-12 text-left py-2 border-bottom">
          <h5 class="mb-0">{{$t('recentBookings')}}</h5>
        </div>
        <div class="w-100 height250" v-if="state==='loading'">
          <div-loader />
        </div>
        <div class="w-100 height250" v-else-if="state==='error'">
          <error-div :message="stateMessage" />
        </div>
        <div class="w-100" v-else>
          <my-orders v-for="(order,index) in orders" :key="'singleOrder'+index" :order="order"/>

          <div class="col-12 py-2 clearfix">
            <b-pagination
                class="m-0 float-right"
                @input="recentOrders"
                v-model="currentPage"
                :total-rows="totalResults"
                :per-page="15"
            ></b-pagination>
          </div>
        </div>

      </div>
    </div>
</template>


<script>
  import MyOrders from "@/components/Profile/Orders/MyOrders";
  import DivLoader from "@/components/Template/DivLoader";
  import ErrorDiv from "@/components/Template/ErrorDiv";
  export default {
    name: "Orders",
    components: {ErrorDiv, DivLoader, MyOrders},
    created() {
      this.recentOrders();
    },
    methods : {
      recentOrders() {
        this.state = "loading";
        this.$http.get("profile/orders",{
          params : {
            page : this.currentPage
          }
        }).then(response=>{
          if(response.data.error) {
            this.state = "error";
            this.stateMessage = response.data.message;
          } else if(response.data.error===undefined) {
            this.state = "error";
            this.stateMessage = this.$t("errors.unableToLoad");
          } else {
            this.state = "success";
            this.orders = response.data.lists;
            this.totalResults = response.data.total;
          }
        }).catch(()=>{
          this.state = "error";
          this.stateMessage = "error";
        });
      }
    },
    data() {
      return {
        state : 'loading',
        stateMessage : "",
        orders : [],
        currentPage : 1,
        totalResults : 0,
      }
    }
  }
</script>